import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {UpperCasePipe} from '@angular/common';

@Component({
  selector: 'app-first-letter',
  imports: [
    UpperCasePipe
  ],
  templateUrl: './first-letter.component.html',
  styleUrl: './first-letter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FirstLetterComponent {
  value = input.required<string>();
}
